export default {
  methods: {
    updateformdata() {
      this.$store.dispatch("getUserProfile", this.user.agencyid).then(data => {
        if (data) {
          this.nosupplier = true;

          this.formdata = data;
        } else {
          this.nosupplier = false;
        }
      });
    }
  }
};
