<template>
  <div>
    <div class="form-group row">
      <label for="contactname" class="col-md-2 col-form-label">Name</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdataComp.contactdetails.contactname }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactemail" class="col-md-2 col-form-label">Email</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdataComp.contactdetails.emailid }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactphonenumber" class="col-md-2 col-form-label"
        >Phone Number</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdataComp.contactdetails.phonenumber }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactaddress" class="col-md-2 col-form-label"
        >Address</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdataComp.contactdetails.address }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactcountry" class="col-md-2 col-form-label"
        >Country</label
      >
      <div class="col-md-4">
        <select
          v-model="formdataComp.contactdetails.country"
          id="countryselect"
          @change="
            getDestinations(
              'country',
              'STATE',
              formdataComp.contactdetails.country
            ),
              (formdataComp.contactdetails.state = ''),
              (formdataComp.contactdetails.city = '')
          "
          :disabled="true"
          class="form-control form-control-sm"
        >
          <option value>Choose Country</option>
          <option
            v-for="item in destinations.country"
            :key="item.destinationId"
            :value="item.id"
            >{{ item.destinationName }}</option
          >
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactstate" class="col-md-2 col-form-label">State</label>
      <div class="col-md-4">
        <select
          v-model="formdataComp.contactdetails.state"
          id="stateselect"
          @change="
            getDestinations('state', 'CITY', formdataComp.contactdetails.state)
          "
          :disabled="true"
          class="form-control form-control-sm"
        >
          <option value>Choose State</option>
          <option
            v-for="item in destinations.state"
            :key="item.destinationId"
            :value="item.id"
            >{{ item.destinationName }}</option
          >
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactcity" class="col-md-2 col-form-label">City</label>
      <div class="col-md-4">
        <select
          v-model="formdataComp.contactdetails.city"
          id="cityselect"
          :disabled="true"
          class="form-control form-control-sm"
        >
          <option value>Choose City</option>
          <option
            v-for="item in destinations.city"
            :key="item.destinationId"
            :value="item.id"
            >{{ item.destinationName }}</option
          >
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactpincode" class="col-md-2 col-form-label"
        >Pin Code</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdataComp.contactdetails.pincode }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactinfo",
  props: {
    formdata: Object,
    destinations: Object
  },
  computed: {
    formdataComp() {
      return this.formdata;
    },
    destinationComp() {
      return this.destinations;
    }
  },
  watch: {
    "destinations.country": function() {
      if (this.formdataComp.contactdetails.country != "") {
        this.getDestinations(
          "country",
          "STATE",
          this.formdataComp.contactdetails.country
        );
      }
    },
    "destinations.state": function() {
      if (this.formdataComp.contactdetails.state != "") {
        this.getDestinations(
          "state",
          "CITY",
          this.formdataComp.contactdetails.state
        );
      }
    }
  },
  methods: {
    getDestinations(parent, type, destId) {
      const dest = this.destinationComp[parent].findIndex(
        data => data.id == destId
      );
      if (dest != -1) {
        this.$store
          .dispatch("getDestinations", {
            type,
            destId: this.destinationComp[parent][dest].destinationId
          })
          .then(data => {
            this.destinationComp[type.toLowerCase()] = data.getDestinations;
          });
      }
    }
  }
};
</script>

<style></style>
