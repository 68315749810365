<template>
  <div>
    <div class="form-group row">
      <label for="agencyid" class="col-md-2 col-form-label">Agency Id</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.agencyid }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="suppname" class="col-md-2 col-form-label">Name</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.name }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="shortname" class="col-md-2 col-form-label">Short Name</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.shortname }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="websiteurl" class="col-md-2 col-form-label"
        >Website Url</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.websiteurl }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="currency" class="col-md-2 col-form-label">Currency</label>
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ currencyData }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="bookemail" class="col-md-2 col-form-label"
        >Booking Email</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.bookingemail }}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="cancelemail" class="col-md-2 col-form-label"
        >Cancellation Email</label
      >
      <div class="col-md-4">
        <div
          style="line-height: 2.1;"
          class="form-control form-control-sm border-0"
        >
          {{ formdata.cancellationemail }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "information",
  props: {
    validate: Object,
    formdata: Object,
    viewData: Boolean,
    currencyData: [Function, String],
    currency: Array,
    editInfo: Boolean
  }
};
</script>

<style></style>
