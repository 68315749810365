<template>
  <div v-if="!user" class="container">
    <form @submit.prevent="handleSigninUser">
      <div style="height: 100vh !important">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-sm-6 border rounded Regular shadow p-4">
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-sm-center">
                <div class="title-supplier">EXTRANET</div>
              </div>
              <div class="col-sm-4">
                <img src="images/secure-login.jpg" class="img-fluid" />
              </div>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-12" v-if="signinError">
                    <span class="text-danger">{{ signinError.message }}</span>
                  </div>
                  <div class="col-sm-12">
                    <div
                      class="form-group"
                      :class="{ invalid: $v.agencyid.$error }"
                    >
                      <input
                        class="form-control"
                        placeholder="Supplier Id"
                        type="text"
                        v-model.trim="agencyid"
                        @blur="$v.agencyid.$touch()"
                      />
                      <span
                        class="text-muted"
                        v-if="$v.agencyid.$error && !$v.agencyid.required"
                        >Supplier Id is Required</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      class="form-group"
                      :class="{ invalid: $v.username.$error }"
                    >
                      <input
                        class="form-control"
                        placeholder="User Name"
                        type="text"
                        v-model.trim="username"
                        @blur="$v.username.$touch()"
                      />
                      <span
                        class="text-muted"
                        v-if="$v.username.$error && !$v.username.required"
                        >User Name is Required</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      class="form-group"
                      :class="{ invalid: $v.password.$error }"
                    >
                      <input
                        class="form-control"
                        placeholder="password"
                        type="password"
                        v-model.trim="password"
                        @blur="$v.password.$touch()"
                      />
                      <span
                        class="text-muted"
                        v-if="$v.password.$error && !$v.password.required"
                        >Password is Required</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <button
                      class="btn btn-info btn-block"
                      type="submit"
                      :disabled="$v.$invalid"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "SignIn",
  data() {
    return {
      agencyid: "",
      username: "",
      password: ""
    };
  },
  validations: {
    agencyid: { required },
    username: { required },
    password: { required }
  },
  computed: {
    ...mapGetters(["user", "signinError"])
  },
  watch: {
    user(value) {
      if (value) window.location.replace("/");
    }
  },
  created() {
    if (this.user) window.location.replace("/");
  },
  methods: {
    handleSigninUser() {
      this.$store.dispatch("signinUser", {
        agencyid: this.agencyid,
        username: this.username,
        password: this.password
      });
    }
  }
};
</script>

<style scoped>
.form-group.invalid input {
  border: 1px solid red;
}
.form-group.invalid label {
  color: red;
}

.title-supplier {
  margin-left: 6px;
  font-size: 15px;
  line-height: 2.8;
  display: block;
}
</style>
