<template>
  <container headTitle="Parent Supplier Information">
    <div slot="content" class="row v-100 pt-3">
      <div class="col-md-10 m-auto">
        <div class="row no-gutters">
          <div class="col-md-2">
            <h6 class="title-head">Supplier Information</h6>
          </div>
        </div>
        <hr />

        <!-- Agenecy Information -->
        <information
          :formdata="formdata"
          :editInfo="editInfo"
          :currency="currency"
          :currencyData="currencyData"
          :viewData="viewData"
        />

        <h6 class="title-head">Contact Information</h6>
        <hr />
        <!-- Contact Information -->
        <contact
          :formdata="formdata"
          :editInfo="editInfo"
          :viewData="viewData"
          :destinations="destinations"
        />
        <hr />
      </div>
    </div>
  </container>
</template>

<script>
import information from "./create/information.vue";
import contact from "./create/contact.vue";
import data from "./helpers/data.js";
import methods from "./helpers/methods.js";
//import validations from "./helpers/validations.js";

import { mapGetters } from "vuex";
export default {
  mixins: [data, methods],
  name: "createsupplier",
  data() {
    return {
      nosupplier: false
    };
  },
  components: {
    information,
    contact
  },
  created() {
    this.$store.dispatch("getCurrency").then(data => {
      this.currency = data.getCurrencies;
    });
    this.$store.dispatch("getDestinations", { type: "COUNTRY" }).then(data => {
      this.destinations.country = data.getDestinations;
    });
    if (this.user) {
      this.updateformdata();
    }
  },
  computed: {
    ...mapGetters(["supplierExists", "supplierEdit", "user"]),
    currencyData() {
      if (this.formdata.currency !== "") {
        const curr = this.currency.findIndex(
          data => data.id == this.formdata.currency
        );
        return `${this.currency[curr].name} (${this.currency[curr].code})`;
      }
      return "";
    }
  }
};
</script>

<style>
.btn-outline-primary {
  border-color: #17a2b8 !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: transparent !important;
  color: inherit !important;
}

.delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}
</style>
