export default {
  data() {
    return {
      currency: [],
      cloneData: {},
      viewData: true,
      editInfo: false,
      submitData: false,
      destinations: {
        country: [],
        state: [],
        city: []
      },
      formdata: {
        agencyid: "",
        name: "",
        shortname: "",
        websiteurl: "",
        currency: "",
        bookingemail: "",
        cancellationemail: "",
        contactdetails: {
          address: "",
          contactname: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
          emailid: "",
          phonenumber: ""
        },
        logindetails: [
          {
            name: "",
            username: "",
            password: "",
            isActive: true,
            type: "new"
          }
        ]
      }
    };
  }
};
