<template>
  <container headTitle="Profile Defails">
    <div slot="content" class="container">
      <div class="row no-gutters">
        <div class="col-md-2 edit-btn">
          <h6 class="title-head">User Profile</h6>
        </div>
        <a
          @click="editMode('editInfo', true)"
          v-if="!editInfo"
          style="height: 25px;"
          class="btn btn-outline-primary col-md-1 btn-sm"
          >Edit</a
        >
      </div>
      <hr />
      <div class="form-group row">
        <label for="agencyid" class="col-md-2 col-form-label">Agency Id</label>
        <div class="col-md-4">
          <div
            style="line-height: 2.1;"
            class="form-control form-control-sm border-0"
          >
            {{ user.subagencyid ? user.subagencyid : user.agencyid }}
          </div>
        </div>
      </div>
      <div class="form-group row" :class="{ invalid: $v.formdata.name.$error }">
        <label for="agencyname" class="col-md-2 col-form-label">Name</label>
        <div class="col-md-4">
          <input
            type="text"
            v-if="editInfo"
            class="form-control form-control-sm"
            id="agencyname"
            v-model="formdata.name"
            @blur="$v.formdata.name.$touch()"
          />
          <div
            v-else
            style="line-height: 2.1;"
            class="form-control form-control-sm border-0"
          >
            {{ formdata.name }}
          </div>
          <span
            class="text-muted"
            v-if="$v.formdata.name.$error && !$v.formdata.name.required"
            >Please provide a valid name</span
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="username" class="col-md-2 col-form-label">Username</label>
        <div class="col-md-4">
          <div
            style="line-height: 2.1;"
            class="form-control form-control-sm border-0"
          >
            {{ formdata.username }}
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        :class="{ invalid: $v.formdata.email.$error }"
      >
        <label for="useremail" class="col-md-2 col-form-label">Email</label>
        <div class="col-md-4">
          <input
            type="text"
            v-if="editInfo"
            class="form-control form-control-sm"
            id="useremail"
            v-model="formdata.email"
            @blur="$v.formdata.email.$touch()"
          />
          <div
            v-else
            style="line-height: 2.1;"
            class="form-control form-control-sm border-0"
          >
            {{ formdata.email }}
          </div>
          <span
            class="text-muted"
            v-if="
              ($v.formdata.email.$error && !$v.formdata.email.required) ||
                !$v.formdata.email.email
            "
            >Please provide a valid email</span
          >
        </div>
      </div>
      <div
        class="form-group row"
        :class="{ invalid: $v.formdata.phonenumber.$error }"
      >
        <label for="usercontact" class="col-md-2 col-form-label">Contact</label>
        <div class="col-md-4">
          <input
            type="text"
            v-if="editInfo"
            class="form-control form-control-sm"
            id="usercontact"
            v-model="formdata.phonenumber"
            @blur="$v.formdata.phonenumber.$touch()"
          />
          <div
            v-else
            style="line-height: 2.1;"
            class="form-control form-control-sm border-0"
          >
            {{ formdata.phonenumber }}
          </div>
          <span
            class="text-muted"
            v-if="
              ($v.formdata.phonenumber.$error &&
                !$v.formdata.phonenumber.required) ||
                !$v.formdata.phonenumber.numeric
            "
            >Please provide a valid contact</span
          >
        </div>
      </div>
      <hr />
      <div class="form-group">
        <button
          @click="Onsubmit"
          class="btn btn-info"
          :disabled="$v.$invalid"
          v-if="editInfo"
        >
          Submit
        </button>
        <button
          @click="editMode('editInfo', false)"
          class="btn btn-danger ml-4"
          v-if="editInfo"
        >
          Cancel
        </button>
      </div>
    </div>
  </container>
</template>

<script>
import { required, email, numeric } from "vuelidate/lib/validators";
export default {
  name: "information",
  data() {
    return {
      formdata: { ...this.$store.getters.user },
      editInfo: false
    };
  },
  watch: {
    user() {
      this.formdata = { ...this.$store.getters.user };
    }
  },
  computed: {
    user: function() {
      return { ...this.$store.getters.user };
    }
  },
  methods: {
    editMode(type, value) {
      if (type == "editInfo" && value == true) {
        this.cloneData = { ...this.formdata };
        this[type] = value;
      } else {
        this.formdata = { ...this.cloneData };
        this[type] = value;
        this.cloneData = {};
      }
    },
    Onsubmit() {
      delete this.formdata.agencyname;
      delete this.formdata.id;
      delete this.formdata.__typename;
      delete this.formdata.menuitems;
      this.$store.dispatch("updateUserProfile", this.formdata).then(data => {
        this.$store.dispatch("getCurrentUser");
      });
      this.editInfo = false;
    }
  },
  validations: {
    formdata: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phonenumber: {
        required,
        numeric
      }
    }
  }
};
</script>

<style scoped>
.btn-outline-primary {
  border-color: #17a2b8 !important;
  line-height: 1.1;
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: transparent !important;
  color: inherit !important;
}
.edit-btn {
  flex: 0 0 10.666667%;
}
</style>
