<template>
  <container headTitle="Change Password">
    <div slot="content" class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div
            class="form-group"
            :class="{ invalid: $v.userdata.oldpass.$error }"
          >
            <label for="oldpass">Old Password</label>
            <input
              v-model.trim="userdata.oldpass"
              @blur="$v.userdata.oldpass.$touch()"
              type="password"
              id="oldpass"
              class="form-control"
            />
            <span
              class="text-muted"
              v-if="$v.userdata.oldpass.$error && !$v.userdata.oldpass.required"
              >old password is required</span
            >
            <span
              class="text-muted"
              v-if="$v.userdata.oldpass.$error && !$v.userdata.oldpass.minLen"
            >
              old pass length is
              {{ $v.userdata.oldpass.$params.minLen.min }} required
            </span>
            <span class="text-muted" v-if="error">{{ error }}</span>
          </div>
          <div
            class="form-group"
            :class="{ invalid: $v.userdata.password.$error }"
          >
            <label for="newpass">New Password</label>
            <input
              v-model.trim="userdata.password"
              @blur="$v.userdata.password.$touch()"
              type="password"
              id="password"
              class="form-control"
            />
            <span
              class="text-muted"
              v-if="
                $v.userdata.password.$error && !$v.userdata.password.required
              "
              >password is required</span
            >
            <span
              class="text-muted"
              v-if="$v.userdata.password.$error && !$v.userdata.password.minLen"
            >
              password length is
              {{ $v.userdata.password.$params.minLen.min }} required
            </span>
            <span
              class="text-muted"
              v-if="
                $v.userdata.password.minLen &&
                  $v.userdata.password.$error &&
                  !$v.userdata.password.sameAs
              "
              >password not be same as oldpassword</span
            >
          </div>
          <div
            class="form-group"
            :class="{ invalid: $v.userdata.confirmpass.$error }"
          >
            <label for="confirmpass">Confirm Password</label>
            <input
              v-model.trim="userdata.confirmpass"
              @blur="$v.userdata.confirmpass.$touch()"
              type="password"
              id="confirmpass"
              class="form-control"
            />
            <span
              class="text-muted"
              v-if="
                $v.userdata.confirmpass.$error &&
                  !$v.userdata.confirmpass.required
              "
              >confirmpassword is required</span
            >
            <span
              class="text-muted"
              v-if="
                $v.userdata.confirmpass.$error &&
                  !$v.userdata.confirmpass.minLen
              "
            >
              confirmpassword length is
              {{ $v.userdata.confirmpass.$params.minLen.min }} required
            </span>
            <span
              class="text-muted"
              v-if="
                $v.userdata.confirmpass.minLen &&
                  $v.userdata.confirmpass.$error &&
                  !$v.userdata.confirmpass.sameAs
              "
              >confirmpassword must be same as password</span
            >
          </div>

          <button
            :disabled="$v.$invalid"
            @click="handleSubmit"
            type="submit"
            class="btn btn-info btnwidth float-right"
          >
            <spinner v-if="submitdata" />Submit
          </button>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import { required, sameAs, minLength, not } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  name: "changepassword",
  data() {
    return {
      userdata: {
        username: "",
        oldpass: "",
        password: "",
        confirmpass: "",
        agencyid: ""
      },
      verified: false,
      submitdata: false,
      error: ""
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    handleSubmit() {
      if (!this.verified) {
        this.userdata.username = this.user.username;
        this.userdata.agencyid = this.user.agencyid;
        delete this.userdata.confirmpass;
        this.$store.dispatch("changePassword", this.userdata).catch(err => {
          if (err) {
            this.error = err.graphQLErrors[0].message;
          }
        });
      }
    }
  },
  validations: {
    userdata: {
      oldpass: {
        required,
        minLen: minLength(7)
      },
      password: {
        required,
        minLen: minLength(7),
        sameAs: not(sameAs("oldpass"))
      },
      confirmpass: {
        required,
        minLen: minLength(7),
        sameAs: sameAs("password")
      }
    }
  }
};
</script>
